import { graphql, useStaticQuery } from 'gatsby';
import { AllDirectusRecentReview, RecentReviewEdges } from './recent-reviews.interface';

export default (): RecentReviewEdges[] => {
  const { allDirectusRecentReview } = useStaticQuery<AllDirectusRecentReview>(graphql`
    query {
      allDirectusRecentReview(sort: { fields: sort, order: ASC }) {
        edges {
          node {
            id
            sort
            customer_name
            company_name
            review
            review_date
            review_platform
            logo {
              width
              height
              data {
                full_url
              }
            }
          }
        }
      }
    }
  `);

  return allDirectusRecentReview.edges;
};
